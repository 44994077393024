.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.yellowButtonDark {
  padding: 5px 25px;
    background: 0 0;
    color: #333;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: 2px solid #afee5d;
    font-size: 1.2rem;
    position: relative;
    display: table;
    margin-top: 10px;
    margin-bottom: 10px;
    width: auto;
    text-decoration: none;
    position: relative;
    z-index: 1;
}

.freigegeben {
  color:green!important;
}

tr:nth-child(even){
  background:#efefef;
}

.loadingSpinner {
  position:absolute;
  top:50%;
  left:50%;
  margin-left: -20px;
}

.loadingOverlay {
  position:fixed;
  left:0;
  top:0;
  width:100vw;
  height:100vh;
  background:rgba(255,255,255,.6);
  z-index:99;
}

.deleteBtn {
  cursor:pointer;
}

.button--red {
  background: #FF0000!important;
  color:#fff!important;
  border:none!important;
}

.button--dark {
  background: rgba(0, 0, 0, 0.87)!important;
  color:#fff!important;
  border:none!important;
}